import styled from "styled-components";

export const Quemsomos = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1170px;
  gap: 120px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
    text-align: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
`;
export const Imagem = styled.div`
  img {
    @media (min-width: 320px) and (max-width: 768px) {
      width: 100vw;
      padding: 60px 20px;
    }
  }
`;

export const Descricao = styled.div`
  font-family: "Oxygen", sans-serif;
  h1 {
    font-weight: Bold;
    font-size: 48px;
    margin: 0 0 10px 0;
  }
  .content-circle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;

    @media (min-width: 320px) and (max-width: 768px) {
      justify-content: center;
    }
  }
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .circle1 {
    background: ${(props) => props.theme.colors.secondary600};
  }
  .circle2 {
    background: ${(props) => props.theme.colors.secondary300};
  }
  .circle3 {
    background: ${(props) => props.theme.colors.secondary};
  }
  p {
    font-size: 16px;
    line-height: 22px;
  }
`;
