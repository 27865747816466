import React from "react";
import * as C from "./styled";
import { Logo } from "../../Icons";
import HamburgerMenu from "../HamburgerMenu";
import { NavItens } from "../../Utils";
import MenuLink from "../MenuLink";

const Nav = () => {
  return (
    <C.Header>
      <C.Container>
        <C.LogoArea>
          <Logo />
        </C.LogoArea>
        <C.NavBar>
          <C.HamburgerMenu>
            <HamburgerMenu />
          </C.HamburgerMenu>

          <C.NavItens>
            {NavItens?.map((item, index) => {
              const { label, path } = item;
              return (
                <ul key={index}>
                  <li>
                    <MenuLink to={path}>{label}</MenuLink>
                  </li>
                </ul>
              );
            })}
          </C.NavItens>
        </C.NavBar>
      </C.Container>
    </C.Header>
  );
};

export default Nav;
