import styled from "styled-components";

export const Rodape = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 70px 0;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  font-family: "Oxygen", sans-serif;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 1170px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
    text-align: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const AreaLogo = styled.div`
  justify-content: flex-start;
  flex-basis: 49%;
  @media (min-width: 320px) and (max-width: 768px) {
    justify-content: center;
    margin-bottom: 60px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #d9d9d9;
    margin: 20px 100px 0 0;

    @media (min-width: 320px) and (max-width: 768px) {
      margin: 40px;
      text-align: center;
    }
  }
`;

export const Links = styled.div`
  justify-content: flex-start;
  flex-basis: 49%;

  h1 {
    font-weight: Bold;
    font-size: 48px;
    margin: 0 0 10px 0;
  }
  .content-circle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;

    @media (min-width: 320px) and (max-width: 768px) {
      justify-content: center;
    }
  }
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .circle1 {
    background: ${(props) => props.theme.colors.secondary600};
  }
  .circle2 {
    background: ${(props) => props.theme.colors.secondary300};
  }
  .circle3 {
    background: ${(props) => props.theme.colors.secondary};
  }
`;

export const AreaLink = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 100px;
  margin-top: 50px;
  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    column-gap: 50px;
  }
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: ease 0.2s;

    :hover {
      color: ${(props) => props.theme.colors.secondary};
      font-weight: bold;
    }
  }
  li {
    list-style: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  .circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .circle1 {
    background: ${(props) => props.theme.colors.secondary};
  }
`;
