import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: ${(props) => props.theme.colors.primary400};

  @media (min-width: 320px) and (max-width: 768px) {
    height: auto;
    padding: 30px 20px;
  }
`;

export const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
    text-align: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const SocialArea = styled.div`
  a {
    opacity: 0.4;
    transition: ease 0.2s;
    :hover {
      opacity: 1;
    }
  }

  svg {
    margin: 0 10px;
  }
`;

export const Link = styled.a``;

export const TextArea = styled.div``;

export const LogoArea = styled.div`
  p {
    font-family: "VT323", monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    color: #999999;
  }
  img {
    width: 76px;
  }
`;
