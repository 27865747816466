import { ThemeProvider } from "styled-components";

export const theme = {
  colors: {
    primary: "#022d47",
    primary400: "#001928",
    secondary: "#FB8500",
    secondary300: "#FD9E02",
    secondary600: "#FFB703",
    text: "#fff",
    textSecondary: "#f4f4f4",
    textTertiary: "#1E1E1E",
  },
};

export function Theme({ children }) {
  return <ThemeProvider theme={theme}> {children} </ThemeProvider>;
}
