import styled from "styled-components";
import Background from "../../assets/Maskgroup.png";
import iconSolar from "../../assets/solarwhite.svg";
import iconProjetos from "../../assets/projetosr.svg";
import iconMoney from "../../assets/money.svg";

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  min-height: 930px;
  width: 100%;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    background-size: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1170px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
    padding: 0 20px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const Chamada = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 500px;
  text-align: center;

  @media (min-width: 320px) and (max-width: 768px) {
    padding: 80px 20px 0 20px;
  }

  h1 {
    font-family: "Oxygen", sans-serif;
    font-style: bold;
    font-size: 36px;
    line-height: 35px;

    @media (min-width: 320px) and (max-width: 768px) {
      font-size: 24px;
      line-height: 22px;
    }
  }
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  font-family: "Oxygen", sans-serif;
  font-style: bold;
  font-size: 18px;
  margin-top: 20px;

  width: 208px;
  height: 50px;

  background: ${(props) => props.theme.colors.secondary300};
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  border: 0;
  color: #fff;
  :hover {
    background: ${(props) => props.theme.colors.secondary600};
    transition: ease 0.3s;
  }
`;

export const Cards = styled.div`
  margin-top: 175px;
  display: flex;
  align-items: center;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    gap: 100px;
  }
`;

export const Card1 = styled.div`
  background-color: ${(props) => props.theme.colors.secondary600};
  border-radius: 10px;
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.text};
  font-family: "Oxygen";
  padding: 30px;
  margin-right: -20px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    height: auto !important;
    margin: 0;
  }

  button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid ${(props) => props.theme.colors.secondary300};
    background: ${(props) => props.theme.colors.secondary} url(${iconProjetos})
      no-repeat center;
    margin-top: -175px;
    cursor: inherit;
  }
  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
  }
  h5 {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.textSecondary};
  }
`;

export const Card2 = styled.div`
  background-color: ${(props) => props.theme.colors.secondary300};
  border-radius: 10px;
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.text};
  font-family: "Oxygen";
  padding: 40px;
  z-index: 1;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    height: auto;
    z-index: 0;
  }

  button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid ${(props) => props.theme.colors.secondary600};
    background: ${(props) => props.theme.colors.secondary300} url(${iconSolar})
      no-repeat center;
    margin-top: -175px;
    cursor: inherit;
  }
  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
  }
  h5 {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.textSecondary};
  }
`;

export const Card3 = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 10px;
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.text};
  font-family: "Oxygen";
  padding: 30px;
  margin-left: -20px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 0;
  }

  button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid ${(props) => props.theme.colors.secondary300};
    background: ${(props) => props.theme.colors.secondary600} url(${iconMoney})
      no-repeat center;
    margin-top: -175px;
    cursor: inherit;
  }
  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
  }
  h5 {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.textSecondary};
  }
`;
