import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ModalBox = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  .form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 50px 20px;

    @media (min-width: 320px) and (max-width: 768px) {
      width: 300px;
      pad: 20px;
    }
  }

  .group {
    position: relative;
  }

  .form .group label {
    font-size: 14px;
    color: ${(props) => props.theme.colors.primary};
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    transition: all 0.3s ease;
  }

  .form .group input,
  .form .group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.primary};
    margin-bottom: 20px;
    outline: 0;
    width: 100%;
    background-color: transparent;
  }

  .form .group input:placeholder-shown + label,
  .form .group textarea:placeholder-shown + label {
    top: 10px;
    background-color: transparent;
  }

  .form .group input:focus,
  .form .group textarea:focus {
    border-color: ${(props) => props.theme.colors.secondary300};
  }

  .form .group input:focus + label,
  .form .group textarea:focus + label {
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: ${(props) => props.theme.colors.secondary300};
    font-weight: 600;
    font-size: 14px;
  }

  .form .group textarea {
    resize: none;
    height: 100px;
  }
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  font-family: "Oxygen", sans-serif;
  font-style: bold;
  font-size: 18px;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  background: ${(props) => props.theme.colors.secondary300};
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 0;
  color: #fff;
  :hover {
    background: ${(props) => props.theme.colors.secondary600};
    transition: ease 0.3s;
  }
`;
