import React, { useLayoutEffect, useRef, useState } from "react";
import * as C from "./styled";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Modal = ({ closeAction }) => {
  const box = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const notifyError = () =>
    toast.error("Erro, preencha todos os campos", { theme: "colored" });
  const notifySuccess = () =>
    toast.success("E-mail enviado com sucesso", { theme: "colored" });

  useLayoutEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const closeModal = () => {
    closeAction();
  };

  const handlerWindowClick = (e) => {
    if (!box.current.contains(e.target)) {
      closeModal();
    }
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      notifyError();
      return;
    }
    const templateParams = {
      from_name: name,
      message: message,
      email: email,
    };
    emailjs
      .send(
        "service_w2whe2z",
        "template_wfum3fh",
        templateParams,
        "sdD5BxoFEf_7DynS1"
      )
      .then(
        (response) => {
          console.log("Email enviado", response.status, response.text);
          notifySuccess();
          setName("");
          setEmail("");
          setMessage("");
          if (response.status === 200) {
            setTimeout(() => {
              closeModal();
            }, 6000);
          }
        },
        (err) => {
          console.log("error", err);
          closeModal();
        }
      );
  };

  return (
    <>
      <C.Container onClick={handlerWindowClick}>
        <C.ModalBox ref={box}>
          <form className="form" onSubmit={handlerSubmit}>
            <div className="group">
              <input
                type="text"
                placeholder="‎"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label>Nome</label>
            </div>
            <div className="group">
              <input
                type="email"
                placeholder="‎"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>E-mail</label>
            </div>
            <div className="group">
              <textarea
                placeholder="‎"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <label>Mensagem</label>
            </div>
            <C.Button type="submit">Enviar</C.Button>
          </form>
        </C.ModalBox>
      </C.Container>
      <ToastContainer />
    </>
  );
};

export default Modal;
