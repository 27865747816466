import React from "react";
import * as C from "./styled";
import { SocialMedia } from "../../Utils";

import Projetaweb from "../../assets/logo-deitada 1.png";

const date = new Date();

const Footer = () => {
  return (
    <C.Footer>
      <C.Container>
        <C.SocialArea>
          {SocialMedia?.map((item, index) => {
            const { icon, path } = item;
            return (
              <C.Link key={index} href={path}>
                {icon}
              </C.Link>
            );
          })}
        </C.SocialArea>
        <C.TextArea>
          <article
            style={{ color: "#D9D9D9", fontFamily: "Oxygen", fontSize: 12 }}
          >
            © Copyright {date.getFullYear()}. Powered by Baypy Electric
          </article>
        </C.TextArea>
        <C.LogoArea>
          <p>Developed By</p>
          <img src={Projetaweb} alt="Projectaweb" />
        </C.LogoArea>
      </C.Container>
    </C.Footer>
  );
};

export default Footer;
