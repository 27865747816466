import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Oxygen";
  text-align: center;
  padding: 0 120px;
  @media (min-width: 320px) and (max-width: 768px) {
    padding: 0 30px;
  }
  .image-area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    box-sizing: border-box;
    border: 3px solid #fd9e02;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
`;

export const TextArea = styled.div`
  p {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin: 20px;
  }
  h2 {
    font-size: 20px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.secondary};
  }
  h4 {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
`;
