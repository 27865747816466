import React from "react";
import * as C from "./styled";

const CarouselCard = ({ item }) => {
  return (
    <C.Container>
      <div className="image-area">{item.image}</div>
      <C.TextArea>
        <p> {item.text}</p>
        <h2> {item.name} </h2>
        <h4> {item.subscript} </h4>
      </C.TextArea>
    </C.Container>
  );
};

export default CarouselCard;
