import React, { useState, useEffect } from "react";
import * as C from "./styled";
import imgQuemsomos from "../../assets/quemsomos.svg";
import Section from "../Section";
import { useSpring, animated } from "react-spring";

const Quemsomos = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 300; // Ajuste conforme necessário

    setIsVisible(scrollTop > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const pageProps = useSpring({
    opacity: isVisible ? 1 : 0,
  });

  return (
    <animated.div style={pageProps}>
      <Section isVisible={isVisible}>
        <C.Quemsomos id="quemsomos" style={pageProps}>
          <C.Container>
            <C.Imagem>
              <img
                src={imgQuemsomos}
                draggable="false"
                alt="Contém produtos eletrônicos"
              />
            </C.Imagem>
            <C.Descricao>
              <h1>Quem Somos</h1>
              <div className="content-circle">
                <span className="circle circle1"></span>
                <div className="circle circle2"></div>
                <div className="circle circle3"></div>
              </div>
              <p>
                Somos uma empresa de engenharia elétrica especializada em
                soluções personalizadas para proteção de sistemas elétricos de
                potência e geração distribuída de energia. Nosso objetivo é
                oferecer soluções seguras e eficientes, que atendam às
                necessidades específicas de cada cliente.
              </p>
              <br />
              <p>
                Oferecemos serviços de manutenção em subestações de energia
                elétrica de alta tensão, proteção e seletividade dos
                equipamentos elétricos, automação e eficiência energética. Na
                parte de geração distribuída de energia, trabalhamos com
                projetos e instalação de sistemas solar fotovoltaica para
                residências, empresas e indústrias. Com um time de engenheiros
                elétricos altamente qualificados e experientes, trabalhamos com
                tecnologias avançadas e estamos sempre atualizados com as
                últimas normas técnicas. Nosso compromisso é oferecer soluções
                inovadoras e eficientes, com ética, transparência e excelência.
              </p>
            </C.Descricao>
          </C.Container>
        </C.Quemsomos>
      </Section>
    </animated.div>
  );
};

export default Quemsomos;
