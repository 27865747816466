import {
  FaceHeader,
  InstaHeader,
  ZapHeader,
  ProjetoService,
  MontagemService,
  ManutencaoService,
  SolarService,
  EstudosService,
  ConsumoService,
  EnderecoContato,
  TelefoneContato,
  EmailContato,
} from "../Icons";

export const NavItens = [
  {
    label: "Início",
    path: "#inicio",
  },
  {
    label: "Quem Somos",
    path: "#quemsomos",
  },
  {
    label: "Serviços",
    path: "#servicos",
  },
  {
    label: "Contato",
    path: "#contato",
  },
];

export const SocialMedia = [
  {
    icon: <FaceHeader />,
    path: "https://www.linkedin.com/company/baypyelectric",
  },
  {
    icon: <InstaHeader />,
    path: "https://www.instagram.com/baypyelectric/",
  },
  {
    icon: <ZapHeader />,
    path: "https://wa.me/5531996852662",
  },
];

export const ServiceItem = [
  {
    icon: <ProjetoService />,
    label: "Projeto e Aprovação junto à Concessionária",
    path: "",
    history:
      "A Baypy Electric conta com equipe de engenharia, especialista em elaboração e desenvolvimento de projetos de Proteção contra Descargas Atmosféricas (SPDA), Aterramentos elétricos, Proteção de sistemas elétricos de potência, Subestação de energia de média e alta tensão.",
  },
  {
    icon: <MontagemService />,
    label: "Montagem Eletromecânica de Subestação",
    path: "",
    history:
      "Realizamos toda terraplanagem, escavações e construção civil da subestação, incluindo sala de comando e pátio de equipamentos. \n Instalação eletromecânica dos:  \nEquipamentos de manobra (chave seccionadora, disjuntores); \nTransformadores de Corrente, Potencial; \nTransformador de Potência; \nMalhas de aterramento; \nBanco de baterias; \nIsoladores, etc.",
  },
  {
    icon: <ManutencaoService />,
    label: "Manutenção Eletromecânica",
    path: "",
    history:
      "Oferecemos serviço de manutenção preventiva em Subestação de Média/Alta tensão, Sistema de Proteção contra Descargas Atmosféricas (SPDA), Usina de Energia Solar, Grupo Gerador Diesel, etc.",
  },
  {
    icon: <SolarService />,
    label: "Energia Solar",
    path: "",
    history:
      "Solução completa em Energia Solar fotovoltaica para residências, condomínios, escolas, comércios e propriedades rurais. Nossa equipe é formada por engenheiros e técnicos, focados em oferecer um futuro mais sustentável e econômico para nossos clientes e parceiros.",
  },
  {
    icon: <EstudosService />,
    label: "Estudos, Medições e Laudos Técnicos",
    path: "",
    history:
      "Realizamos medições e inspeção técnicas em Sistema de Aterramento, Grandezas Elétricas, Inspeção Termográfica, Qualidade de Energia Elétrica, etc. Trabalhamos com elaboração de estudos elétricos que permitem uma completa análise das instalações elétricas. \n Dentre os estudos que executamos, encontram-se:\n- Laudo Elétrico de SPDA (Sistema de Proteção contra Descargas Atmosféricas);\n- Estudo de coordenação e seletividade;\n- Estudo e medição de aterramento;\n- Inspeção Termografia.",
  },
  {
    icon: <ConsumoService />,
    label: "Mercado Livre de Energia",
    path: "",
    history:
      "A adequação do sistema de medição para faturamento de energia é fundamental paraatender às exigências dos órgãos reguladores: Operador Nacional do Sistema Elétrico(ONS) e Câmara de Comercialização de Energia Elétrica (CCEE). Realizamos a implantação do sistema de medição para faturamento de energia – SMF, com os seguintes serviços: \nElaboração do projeto elétrico; \nAprovação do projeto junto à concessionária de energia; \nMontagem dos equipamentos e comissionamento do SMF.",
  },
];

export const ContatoList = [
  {
    icon: <EnderecoContato />,
    label: "Endereço",
    description: "Rua Marcelino Benvindo, Centro, Caetité-BA",
    path: "",
  },
  {
    icon: <TelefoneContato />,
    label: "Telefone",
    description: "(77) 99837-6448",
    path: "",
  },
  {
    icon: <EmailContato />,
    label: "E-mail",
    description: "contato@baypy.com",
    path: "",
  },
];
