import React, { useState, useEffect } from "react";
import * as C from "./styled";
import { ContatoList } from "../../Utils";
import Modal from "../Modal";
import Section from "../Section";
import { useSpring, animated } from "react-spring";

const Contato = () => {
  const [show, setShow] = useState(false);

  const handlerModal = () => {
    setShow(true);
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 2400; // Ajuste conforme necessário

    setIsVisible(scrollTop > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const pageProps = useSpring({
    opacity: isVisible ? 1 : 0,
  });

  return (
    <>
      {" "}
      {show && <Modal closeAction={() => setShow(false)} />}
      <C.Contato id="contato">
        <animated.div style={pageProps}>
          <Section isVisible={isVisible}>
            <C.Container>
              <C.Title>
                <h1>Contato</h1>
                <div className="content-circle">
                  <span className="circle circle1"></span>
                  <div className="circle circle2"></div>
                  <div className="circle circle3"></div>
                </div>
                <p>
                  Para entrar em contato conosco, você pode utilizar a caixa de
                  chat disponível nesta plataforma. Basta digitar sua mensagem e
                  nós responderemos o mais rápido possível.
                </p>
                <C.Button onClick={() => handlerModal()}>
                  Entre em contato
                </C.Button>
              </C.Title>
              <C.Infos>
                {ContatoList?.map((item, index) => {
                  const { icon, label, description, path } = item;

                  return (
                    <C.InfoArea key={index} href={path}>
                      <C.ImgArea>{icon}</C.ImgArea>
                      <C.Info>
                        <h2>{label}</h2>
                        <p>{description}</p>
                      </C.Info>
                    </C.InfoArea>
                  );
                })}
              </C.Infos>
            </C.Container>
          </Section>
        </animated.div>
      </C.Contato>
    </>
  );
};

export default Contato;
