// Section.js
import React from "react";
import { useSpring, animated } from "react-spring";

const Section = ({ isVisible, children }) => {
  const props = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0)" : "translateY(50px)",
  });

  return <animated.div style={props}>{children}</animated.div>;
};

export default Section;
