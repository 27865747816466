import styled from "styled-components";

export const Testemunhas = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 70px 0;
  background: ${(props) => props.theme.colors.text};
  color: ${(props) => props.theme.colors.textTertiary};
  font-family: "Oxygen", sans-serif;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1170px;
  height: auto;
  flex-direction: column;
  background: #fff;
  font-family: "Oxygen", sans-serif;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
    text-align: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 98vw;
    padding: 0 20px;
  }
  h1 {
    font-weight: Bold;
    font-size: 48px;
    margin: 0 0 10px 0;
  }
  .content-circle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;
  }
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .circle1 {
    background: ${(props) => props.theme.colors.secondary600};
  }
  .circle2 {
    background: ${(props) => props.theme.colors.secondary300};
  }
  .circle3 {
    background: ${(props) => props.theme.colors.secondary};
  }
  p {
    font-size: 16px;
    line-height: 22px;
  }
`;
