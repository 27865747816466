import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.secondary};
  height: 40px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 1170px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    padding: 0 20px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const IconArea = styled.div`
  display: flex;
  padding: 0px;
  gap: 20px;

  a {
    opacity: 0.4;
    transition: ease 0.2s;
    :hover {
      opacity: 1;
    }
  }
`;
export const Link = styled.a``;
