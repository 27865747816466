import React, { useState } from "react";
import * as C from "./styled";
import { NavItens } from "../../Utils";
import MenuLink from "../MenuLink";

function HamburgerMenu() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <C.MenuIcon open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </C.MenuIcon>
      {open && <C.Overlay onClick={() => setOpen(false)} />}
      <C.Nav open={open} onClick={() => setOpen(!open)}>
        {NavItens?.map((item, index) => {
          const { label, path } = item;
          return (
            <ul key={index}>
              <li>
                <MenuLink to={path}>{label}</MenuLink>
              </li>
            </ul>
          );
        })}
      </C.Nav>
    </>
  );
}

export default HamburgerMenu;
