import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.1);
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1170px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    padding: 0 20px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const LogoArea = styled.div`
  svg {
    width: 125px;
  }
`;

export const NavBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 60px;
  color: ${(props) => props.theme.colors.primary};
  li {
    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    transition: ease 0.2s;

    :hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`;
export const HamburgerMenu = styled.nav`
  @media (min-width: 768px) {
    display: none;
  }
`;
export const NavItens = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 60px;

  @media (max-width: 767px) {
    display: none;
  }
`;
