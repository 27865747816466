import React from "react";
import * as C from "./styled";

const Banner = () => {
  return (
    <>
      <C.Banner>
        <C.Container>
          <C.Chamada>
            <h1>Soluções inovadoras em energia elétrica</h1>
            {/* <C.Button onClick={() => handlerModal()}>Entre em contato</C.Button> */}
          </C.Chamada>
          <C.Cards>
            <C.Card1>
              <button></button>
              <h1>Projeto Elétrico</h1>
              <h5>
                Nosso time de engenharia realiza estudos de proteção, testes e
                comissionamento dos equipamentos elétricos de potência com foco
                na segurança, eficiência e confiabilidade. Todos os projetos são
                desenvolvidos em conformidade com normas técnicas nacionais e
                internacionais.
              </h5>
            </C.Card1>
            <C.Card2>
              <button></button>
              <h1>Energia Solar</h1>
              <h5>
                Oferecemos soluções em geração de energia solar, solicite uma
                proposta técnica- comercial e reduza os custos com energia
                elétrica no seu negócio. Além de gerar sua própria energia e
                economizar até 95% na conta, você ajuda na conservação do meio
                ambiente, introduzindo práticas sustentáveis no dia a dia da sua
                empresa.
              </h5>
            </C.Card2>
            <C.Card3>
              <button></button>
              <h1>Mercado Livre</h1>
              <h5>
                Ajudamos nossos clientes a reduzirem seus custos com energia
                elétrica por meio de soluções em eficiência energética e auxílio
                na migração para mercado livre de energia. Economize até 35% na
                conta e terceirize toda a gestão de energia elétrica da sua
                empresa com segurança.
              </h5>
            </C.Card3>
          </C.Cards>
        </C.Container>
      </C.Banner>
    </>
  );
};

export default Banner;
