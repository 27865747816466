import styled from "styled-components";
import bgServices from "../../assets/bg_services.svg";

export const Services = styled.div`
  display: flex;
  justify-content: center;
  padding: 70px 0;
  width: 100%;
  min-height: 1024px;
  background-image: url(${bgServices});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  background-color: #01131f;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    background-size: auto;
    background-position: center;
    background-repeat: repeat;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
    text-align: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const Title = styled.div`
  font-family: "Oxygen", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    text-align: center;
  }
  h1 {
    font-weight: Bold;
    font-size: 48px;
    margin: 0;
  }
  .content-circle {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (min-width: 320px) and (max-width: 768px) {
      flex-direction: row;
      margin-bottom: 30px;
    }
  }
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .circle1 {
    background: ${(props) => props.theme.colors.secondary600};
  }
  .circle2 {
    background: ${(props) => props.theme.colors.secondary300};
  }
  .circle3 {
    background: ${(props) => props.theme.colors.secondary};
  }
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.textSecondary};
    margin-left: 40px;

    @media (min-width: 320px) and (max-width: 768px) {
      margin: 0;
      font-size: 16px;
      line-height: 16px;
      width: 100%;
    }
  }
`;

export const ServiceItems = styled.div`
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin-top: 60px;
  cursor: pointer; */

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin-top: 60px;
  cursor: pointer;
  place-items: center;

  @media (min-width: 320px) and (max-width: 768px) {
    /* flex-direction: column;
    flex-wrap: nowrap; */

    grid-template-columns: 1fr;
    place-items: center;
  }
`;
export const Link = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary400};
  border-radius: 10px;
  padding: 75px 25px;
  height: 320px;
  max-height: 320px;
  flex-basis: 21%;
  width: 260px;
  max-width: 260px;
  color: ${(props) => props.theme.colors.text};
  transition: ease 0.2s;
  cursor: inherit;

  :hover {
    background-color: ${(props) => props.theme.colors.text};
    color: ${(props) => props.theme.colors.secondary};
  }

  h6 {
    margin-top: 40px;
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }
  p {
    line-height: 32px;
  }
`;
