import styled from "styled-components";

export const MenuIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  cursor: pointer;

  div {
    width: 24px;
    height: 2px;
    background: ${(props) => props.theme.colors.primary400};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const Nav = styled.nav`
  position: fixed;
  top: 125px;
  left: ${({ open }) => (open ? "0" : "-100%")};
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 2;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    li {
      margin: 1.2rem;
    }

    a {
      font-size: 2rem;
      text-decoration: none;
      color: #333;
      transition: color 0.3s linear;

      &:hover {
        color: #f9a826;
      }
    }
  }
`;
