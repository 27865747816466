import Avatar1 from "../assets/avatar1.png";
import Avatar2 from "../assets/avatar2.jpeg";
import Avatar3 from "../assets/avatar3.jpeg";
import Avatar4 from "../assets/avatar4.jpeg";

export const CarouselInformation = [
  {
    image: <img src={Avatar1} alt="Foto de José Aires" />,
    text: "Foi incrível em nos ajudar a fazer a transição para energia limpa e renovável em nossa empresa. Eles foram muito profissionais e atenciosos em cada etapa do processo, desde o projeto até a instalação e configuração do sistema de energia solar.",
    name: "José Aires de lima",
    subscript: "Proprietário da Sublime",
  },
  {
    image: <img src={Avatar2} alt="Foto de Clarice dos Santos" />,
    text: "A adesão da geração de energia solar, sempre foi um objetivo da nossa empresa, substituindo a energia elétrica convencional, reduzindo de fato os custos, além do baixo impacto ambiental. Estamos nuito satisfeitos com os serviços oferecidos pela Baypy Electric, que, de forma simples, descomplicada e segura, atingiu todas as nossas expectativas.",
    name: "Clarice dos Santos",
    subscript: "Proprietária da Ilha Gourmet",
  },
  {
    image: <img src={Avatar3} alt="Foto de Albeni Ferreira de Oliveira" />,
    text: "Estou muito satisfeito com os serviços prestados pela Baypy Electric, os profissionais foram muito comprometidos e pontuais com meu empreendimento, sempre atenciosos, tiraram todas as minhas dúvidas. Um diferencial que julgo importante, é o fato que após a conclusão dos serviços a empresa está sempre disponível quando eu preciso, essa disponibilidade faz toda diferença!",
    name: "Albeni Ferreira de Oliveira",
    subscript: "Sócio proprietário da Pop English",
  },
  {
    image: <img src={Avatar4} alt="Foto de Valdir Luís Heck" />,
    text: "Após uma grande decepção de compra de equipamentos de energia solar, onde a empresa vendedora não realizou a instalação, tive o imenso prazer de conhecer a Baypy Electric, que resolveu todos os meus problemas, desde o projeto de liberação junto a CEMIG e sua instalação, de forma profissional e competente. Indico e asseguro o profissionalismo da empresa e seus colaboradores.",
    name: "Valdir Luís Heck",
    subscript: "Sócio proprietário do Posto Vanete Ltda",
  },
];
