import React, { useLayoutEffect, useRef } from "react";
import * as C from "./styled";

const ModalItems = ({ children, closeAction }) => {
  const box = useRef();

  useLayoutEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const closeModal = () => {
    closeAction();
  };

  const handlerWindowClick = (e) => {
    if (!box.current.contains(e.target)) {
      closeModal();
    }
  };
  return (
    <>
      <C.Container onClick={handlerWindowClick}>
        <C.ModalBox ref={box}>{children}</C.ModalBox>
      </C.Container>
    </>
  );
};

export default ModalItems;
