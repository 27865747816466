import React, { useState, useEffect } from "react";
import * as C from "./styled";
import CarouselCard from "../CarouselCard";
import { Swiper, SwiperSlide } from "swiper/react";
import Section from "../Section";
import { useSpring, animated } from "react-spring";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

import { Autoplay, Navigation } from "swiper";

import { CarouselInformation } from "../../CarouselInformations";

const Carousel = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 1000; // Ajuste conforme necessário

    setIsVisible(scrollTop > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const pageProps = useSpring({
    opacity: isVisible ? 1 : 0,
  });

  return (
    <C.Testemunhas>
      <animated.div style={pageProps}>
        <Section isVisible={isVisible}>
          <C.Container>
            <h1 style={{ color: "#022d47" }}>Testemunhos</h1>
            <div className="content-circle">
              <span className="circle circle1"></span>
              <div className="circle circle2"></div>
              <div className="circle circle3"></div>
            </div>
            <Swiper
              style={{
                "--swiper-navigation-color": "#777",
              }}
              navigation={true}
              modules={[Navigation, Autoplay]}
              autoplay={{
                delay: 9000,
                disableOnInteraction: false,
              }}
              className="mySwiper"
            >
              {CarouselInformation?.map((item, index) => (
                <SwiperSlide key={index}>
                  <CarouselCard item={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </C.Container>
        </Section>
      </animated.div>
    </C.Testemunhas>
  );
};

export default Carousel;
