import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;

  a {
    height: 1000px !important;
    width: 1000px !important;
    max-width: 600px;
    max-height: 1000px !important;
    padding: 40px;
    background-color: ${(props) => props.theme.colors.text};
    color: ${(props) => props.theme.colors.primary400};

    @media (min-width: 320px) and (max-width: 768px) {
      /* flex-direction: column;
    flex-wrap: nowrap; */

      max-width: 90vw;
    }

    :hover {
      background-color: ${(props) => props.theme.colors.text};
      color: ${(props) => props.theme.colors.primary400};
    }

    h6 {
      color: ${(props) => props.theme.colors.secondary};
      margin-bottom: 40px;
    }

    p {
      width: 100%;
      font-size: 18px;
    }
  }
`;
