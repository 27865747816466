import styled from "styled-components";

export const Contato = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 70px 0;
  background: ${(props) => props.theme.colors.text};
  color: ${(props) => props.theme.colors.textTertiary};
  font-family: "Oxygen", sans-serif;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 1170px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
    text-align: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const Title = styled.div`
  justify-content: flex-start;
  flex-basis: 49%;

  @media (min-width: 320px) and (max-width: 768px) {
    justify-content: center;
    margin-bottom: 100px;
  }
  h1 {
    font-weight: Bold;
    font-size: 48px;
    margin: 0 0 10px 0;
    color: ${(props) => props.theme.colors.primary};
  }
  .content-circle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;

    @media (min-width: 320px) and (max-width: 768px) {
      justify-content: center;
    }
  }
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .circle1 {
    background: ${(props) => props.theme.colors.secondary600};
  }
  .circle2 {
    background: ${(props) => props.theme.colors.secondary300};
  }
  .circle3 {
    background: ${(props) => props.theme.colors.secondary};
  }
  P {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
    max-width: 370px;
  }
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  font-family: "Oxygen", sans-serif;
  font-style: bold;
  font-size: 18px;
  margin-top: 20px;

  width: 208px;
  height: 50px;

  background: ${(props) => props.theme.colors.secondary300};
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  border: 0;
  color: #fff;
  :hover {
    background: ${(props) => props.theme.colors.secondary600};
    transition: ease 0.3s;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    margin: auto;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-basis: 49%;
  flex-direction: column;
  row-gap: 20px;
  @media (min-width: 320px) and (max-width: 768px) {
    row-gap: 80px;
  }
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const ImgArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 25px;

  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0;
  }
`;
export const Info = styled.span`
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.secondary};
    @media (min-width: 320px) and (max-width: 768px) {
      margin: 5px;
    }
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 15px;
  }
`;
