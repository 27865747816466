import React from "react";
import * as C from "./styled";
import { SocialMedia } from "../../Utils";

const Header = () => {
  return (
    <C.Header id="inicio">
      <C.Container>
        <C.IconArea>
          {SocialMedia?.map((item, index) => {
            const { icon, path } = item;
            return (
              <C.Link key={index} href={path}>
                {icon}
              </C.Link>
            );
          })}
        </C.IconArea>
      </C.Container>
    </C.Header>
  );
};

export default Header;
