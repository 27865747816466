import React, { useState, useEffect } from "react";
import * as C from "./styled";
import LogoRodape from "../../assets/logo2.png";
import { NavItens } from "../../Utils";
import MenuLink from "../MenuLink";
import Section from "../Section";
import { useSpring, animated } from "react-spring";

const Rodape = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 2600; // Ajuste conforme necessário

    setIsVisible(scrollTop > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const pageProps = useSpring({
    opacity: isVisible ? 1 : 0,
  });

  return (
    <C.Rodape>
      <animated.div style={pageProps}>
        <Section isVisible={isVisible}>
          <C.Container>
            <C.AreaLogo>
              <img
                src={LogoRodape}
                draggable="false"
                alt="Logo Baypy Electric"
              />
              <p>
                Somos uma empresa de engenharia elétrica que se dedica a
                oferecer soluções inovadoras e eficientes para as necessidades
                dos nossos clientes, sempre com segurança, responsabilidade e
                ética. Buscamos excelência no atendimento e satisfação do
                cliente, além de promover soluções sustentáveis e responsáveis
                para um futuro melhor.
              </p>
            </C.AreaLogo>
            <C.Links>
              <h1>Links Úteis</h1>
              <div className="content-circle">
                <span className="circle circle1"></span>
                <div className="circle circle2"></div>
                <div className="circle circle3"></div>
              </div>
              <C.AreaLink>
                {NavItens?.map((item, index) => {
                  const { label, path } = item;
                  return (
                    <ul key={index}>
                      <li>
                        <MenuLink to={path}>
                          <div className="circle circle1"></div>
                          {label}
                        </MenuLink>
                      </li>
                    </ul>
                  );
                })}
              </C.AreaLink>
            </C.Links>
          </C.Container>
        </Section>
      </animated.div>
    </C.Rodape>
  );
};

export default Rodape;
