import React, { useState, useEffect } from "react";
import * as C from "./styled";
import { ServiceItem } from "../../Utils";
import ModalItems from "../ModalItems";
import Section from "../Section";
import { useSpring, animated } from "react-spring";

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState({});

  const handlerModal = (services) => {
    setShowModal(true);
    setSelectedServices(services);
  };

  const handlerClose = () => {
    setShowModal(false);
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 1600; // Ajuste conforme necessário

    setIsVisible(scrollTop > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const pageProps = useSpring({
    opacity: isVisible ? 1 : 0,
  });

  return (
    <C.Services id="servicos">
      <animated.div style={pageProps}>
        <Section isVisible={isVisible}>
          <C.Container>
            <C.Title>
              <h1>Serviços</h1>
              <div className="content-circle">
                <span className="circle circle1"></span>
                <div className="circle circle2"></div>
                <div className="circle circle3"></div>
              </div>
              <h4>Conheça nossos serviços e conte com a Baypy Electric</h4>
            </C.Title>
            <C.ServiceItems>
              {ServiceItem?.map((item, index) => (
                <article key={index}>
                  <C.Link onClick={() => handlerModal(item)}>
                    {item?.icon}
                    <h6>{item?.label}</h6>
                  </C.Link>
                </article>
              ))}
            </C.ServiceItems>
          </C.Container>
        </Section>
      </animated.div>
      {showModal && (
        <ModalItems closeAction={() => handlerClose()}>
          <C.Link>
            {selectedServices?.icon}
            <h6>{selectedServices.label}</h6>
            {selectedServices.history.split("\n").map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </C.Link>
        </ModalItems>
      )}
    </C.Services>
  );
};

export default Services;
