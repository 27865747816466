import React from "react";

function MenuLink({ to, children }) {
  const handleClick = (event) => {
    event.preventDefault();
    const section = document.querySelector(to);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <a href={to} onClick={handleClick}>
      {children}
    </a>
  );
}

export default MenuLink;
