import { Theme } from "./styles/Theme";
import { GlobalStyle } from "./styles/global";
import { Normalize } from "styled-normalize";
import Header from "./Components/Header";
import Nav from "./Components/Nav";
import Banner from "./Components/Banner";
import Footer from "./Components/Footer";
import Quemsomos from "./Components/Quemsomos";
import Services from "./Components/Services";
import Carousel from "./Components/Carousel";
import Contato from "./Components/Contato";
import Rodape from "./Components/Rodape";

function App() {
  return (
    <Theme>
      <Header />
      <Nav />
      <Banner />
      <Quemsomos />
      <Carousel />
      <Services />
      <Contato />
      <Rodape />
      <Footer />
      <GlobalStyle />
      <Normalize />
    </Theme>
  );
}

export default App;
